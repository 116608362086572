import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import mapImage from "../../assets/images/contact/mapImage.png"
import NDBWrapper from "../wrapper"

const FindUs = () => {
  return (
    <NDBWrapper id="find-us">
      <p className="find-us-title">Find us</p>

      <div className="find-us">
        <div className="find-us__locations">
          <div className="location">
            <p className="country">United States of America</p>
            <p className="city">San Francisco, CA 94111</p>
          </div>

          <div className="location">
            <p className="country">United Kingdom</p>
            <p className="city">London, EC2A 2BB</p>
          </div>


          <div className="find-us__contact">
            <p>Contact us</p>
            <div className="phone">
              <span>Email</span>
              <a href="mailto:info@vecito.com">contact@vecito.com</a>
            </div>
          </div>
        </div>
        <div className="find-us__map">
          <LazyLoadImage src={mapImage} className="mobile-hide" alt="Find us map" />
          <LazyLoadImage
            src={mapImage}
            className="mobile-show"
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="Find us map"
          />
        </div>
      </div>
    </NDBWrapper>
  )
}

export default FindUs
